import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles/employee.module.scss';
import Trip from '../account/components/trips/trip';
import { ANCHORS } from '../../bi/constants/employee';
import LinkToNewTab from '../../components/LinkToNewTab';

const tripsFormHeaders = [
  { key: 'header1', text: '#' },
  { key: 'header2', text: 'Дата начала' },
  { key: 'header3', text: 'Дата окончания' },
  { key: 'header4', text: 'Название' },
  { key: 'header5', text: 'Услуги' },
  { key: 'header6', text: 'Сотрудники' },
  { key: 'header7', text: 'Сумма' },
];
class EmployeeTrips extends Component {
  static propTypes = {
    setRef: PropTypes.func.isRequired,
    trips: PropTypes.array,
    accountId: PropTypes.number.isRequired,
    companyId: PropTypes.number.isRequired,
  };

  static defaultProps = {
    trips: [],
  };

  renderHeader = () => {
    const { trips } = this.props;
    if (!trips.length) {
      return <div className={ styles.form_head }>У сотрудника нет активных поездок</div>;
    }

    return (
      <div>
        <div className={ styles.form_head }>Активные поездки cотрудника:</div>
        <ul className={ styles.form_row }>
          {tripsFormHeaders.map(({ key, text }) => (
            <li key={ key } className={ styles.form_col }>{text}</li>
          ))}
        </ul>
      </div>
    );
  }

  renderAllCompaniesLink = () => {
    const { companyId, accountId } = this.props;
    return (
      <div className={ styles.row }>
        <LinkToNewTab to={ `/account/${accountId}/company/${companyId}/drips?toScroll=true` }>
          <div className={ styles.trip_link }>Все поездки компании</div>
        </LinkToNewTab>
      </div>
    );
  }

  renderList = () => {
    const { trips, accountId, companyId } = this.props;
    if (!trips.length) {
      return null;
    }

    return trips.map(item => (
      <div key={ item.Id } className={ styles.form_item }>
        <Trip
          item={ item }
          companyId={ companyId }
          accountId={ accountId }
          isEmployeeTrip
        />
      </div>
    ));
  };

  render() {
    const { setRef } = this.props;
    return (
      <div id='trip' className={ styles.form_trip } ref={ ref => setRef(ANCHORS.TRIP, ref) }>
        <div className={ styles.title }>
          ПОЕЗДКИ
        </div>
        {this.renderAllCompaniesLink()}
        {this.renderHeader()}
        {this.renderList()}
      </div>
    );
  }
}

export default EmployeeTrips;
