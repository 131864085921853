import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles/employee.module.scss';
import { ANCHORS, TITLE } from '../../bi/constants/employee';
import { preparedDocumentText } from '../../bi/utils/employee';

const EmployeeDocuments = ({ employee, setRef }) => {
  if (!employee.Documents.length) {
    return null;
  }

  const renderDocumentItems = items => items.map(({ text, value, key }) => (
    value ?
    (<div className={ styles.row } key={ key }>
      <div className={ styles['col-1-2'] }>
        <label>{text}</label>
      </div>
      <div className={ styles['col-1-3'] }>
        <span>{value}</span>
      </div>
    </div>) : null
  ));

  const renderDocumentsList = () => (
    employee.Documents.map((document) => {
      const documentText = preparedDocumentText(document, employee.Code);
      if (!documentText) {
        return null;
      }

      return (
        <div key={ document.Id } className={ styles.doc_wrap }>
          <div className={ styles.title_medium }>{documentText.label}</div>
          {renderDocumentItems(documentText.items)}
        </div>
      );
    })
  );

  return (
    <div id='documents' className={ styles.col1 } ref={ ref => setRef(ANCHORS.DOCUMENT, ref) } >
      <div className={ styles.title }>
        {TITLE.EMPLOYEE_DOCUMENTS}
      </div>
      { renderDocumentsList() }
    </div>
  );
};

EmployeeDocuments.propTypes = {
  employee: PropTypes.object.isRequired,
  setRef: PropTypes.func.isRequired,
};

export default EmployeeDocuments;
