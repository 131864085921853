import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import SearchInput from '../../components/searchInput';
import Checkbox from '../../components/checkbox';
import HotelsSearch from './HotelsSearch';
import RegionsSearch from './RegionsSearch';

import styles from './styles/header.module.css';

const COMPANIES_ROUTE = '/companies';

const LABELS = {
  INPUT_COMPANY_INN: 'Введите ИНН компании',
  SEARCH_BY_INN: 'Поиск по ИНН',
};

const FIELDS = {
  CHANGE_IS_SEARCH_BY_INN: 'changeIsSearchByInn',
};

class HeaderComponents extends Component {
  static propTypes = {
    filterService: PropTypes.object.isRequired,
    silenceChangeUrlQuery: PropTypes.func,
    searchUsersService: PropTypes.object.isRequired,
    hotelsService: PropTypes.object.isRequired,
    regionsService: PropTypes.object.isRequired,
  };

  static defaultProps = {
    silenceChangeUrlQuery: () => {},
  }

  constructor(props) {
    super(props);

    const { searchFilter, type } = props.filterService.get();
    const searchUsersStore = props.searchUsersService.get();
    const { search, requestHotelsPayload } = props.hotelsService.get();
    const { search: regionsSearch } = props.regionsService.get();
    const { isSearchByInn } = searchFilter;

    this.state = {
      searchUsersInput: {
        ...searchUsersStore,
      },
      searchInput: {
        ...searchFilter,
      },
      searchHotelsInput: {
        ...search,
      },
      searchRegionsInput: {
        ...regionsSearch,
      },
      requestHotelsPayload,
      type,
      isSearchByInn,
    };
  }

  componentDidMount() {
    const { filterService, searchUsersService, hotelsService, regionsService } = this.props;

    this.unsubscribeHotels = hotelsService.subscribe(this.updateHotels);
    this.unsubscribeFilter = filterService.subscribe(this.updateState);
    this.unsubscribeSearchUsers = searchUsersService.subscribe(this.updateStateByUsers);
    this.unsubscribeRegions = regionsService.subscribe(this.updateRegions);
  }

  componentWillUnmount() {
    this.props.filterService.cancelSearchFilter();

    if (this.unsubscribeFilter) this.unsubscribeFilter();
    if (this.unsubscribeSearchUsers) this.unsubscribeSearchUsers();
    if (this.unsubscribeHotels) this.unsubscribeHotels();
    if (this.unsubscribeRegions) this.unsubscribeRegions();
  }

  updateHotels = ({ search, requestHotelsPayload }) => {
    this.setState({
      searchHotelsInput: {
        ...search,
      },
      requestHotelsPayload,
    });
  }

  updateRegions = ({ search }) => {
    this.setState({
      searchRegionsInput: {
        ...search,
      },
    });
  }

  updateState = () => {
    const { searchFilter, type, queryString } = this.props.filterService.get();

    this.setState({
      ...this.state,
      searchInput: {
        ...searchFilter,
      },
      isSearchByInn: searchFilter.isSearchByInn,
      type,
    }, () => {
      if (queryString) {
        this.props.silenceChangeUrlQuery(queryString);
      }
    });
  };

  updateStateByUsers = () => {
    const searchUsersStore = this.props.searchUsersService.get();

    this.setState({
      ...this.state,
      searchUsersInput: {
        ...searchUsersStore,
      },
    });
  };

  onSubmitSearch = (value) => {
    this.props.filterService.changeSearchFilterValue(value);
  };

  onSubmitSearchUsers = (value) => {
    this.props.searchUsersService.changeSearchUsersValue(value);
  };

  onChangeSearchByInn = (e, field, value) => {
    this.props.filterService.changeIsSearchByInn(value);
  };

  renderSearchInput = () => {
    const { searchInput, isSearchByInn } = this.state;

    return (
      <SearchInput
        label={ isSearchByInn ? LABELS.INPUT_COMPANY_INN : searchInput.label }
        defaultValue={ searchInput.value }
        onSubmit={ this.onSubmitSearch }
      />
    );
  };

  renderSearchUsersInput = () => {
    const { searchUsersInput } = this.state;

    return (
      <SearchInput
        label={ searchUsersInput.label }
        defaultValue={ searchUsersInput.value }
        onSubmit={ this.onSubmitSearchUsers }
      />
    );
  };

  renderHotelsInput = () => {
    const { searchHotelsInput, requestHotelsPayload } = this.state;
    const { hotelsService } = this.props;

    return (
      <HotelsSearch
        hotelsService={ hotelsService }
        searchHotelsInput={ searchHotelsInput }
        requestHotelsPayload={ requestHotelsPayload }
      />
    );
  };

  renderRegionsInput = () => {
    const { searchRegionsInput } = this.state;
    const { regionsService } = this.props;

    return (
      <RegionsSearch
        regionsService={ regionsService }
        searchRegionsInput={ searchRegionsInput }
      />
    );
  };

  renderCheckbox = () => {
    const companiesEndpoint = window.location.pathname;

    if (companiesEndpoint !== COMPANIES_ROUTE) {
      return null;
    }

    return (
      <div className={ styles['search-orders'] }>
        <Checkbox
          label={ LABELS.SEARCH_BY_INN }
          field={ FIELDS.CHANGE_IS_SEARCH_BY_INN }
          value={ this.state.isSearchByInn }
          onChange={ this.onChangeSearchByInn }
        />
      </div>
    );
  }

  getSearchInput = () => {
    const { searchInput, searchUsersInput, searchHotelsInput, searchRegionsInput } = this.state;

    if (searchInput.init) {
      return this.renderSearchInput();
    }

    if (searchUsersInput.init) {
      return this.renderSearchUsersInput();
    }

    if (searchHotelsInput.init) {
      return this.renderHotelsInput();
    }

    if (searchRegionsInput.init) {
      return this.renderRegionsInput();
    }

    return null;
  }

  render() {
    const { searchHotelsInput } = this.state;
    const searchHtml = this.getSearchInput();
    const searchWrapClass = `${searchHotelsInput.init ? styles.hotels_wrap : ''} ${styles.search}`;

    return (
      <div className={ styles.header }>
        <div className={ searchWrapClass }>
          { searchHtml }
        </div>
        { this.renderCheckbox() }
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <HeaderComponents
      filterService={ opts.filterService }
      silenceChangeUrlQuery={ opts.silenceChangeUrlQuery }
      searchUsersService={ opts.searchUsersService }
      hotelsService={ opts.hotelsService }
      regionsService={ opts.regionsService }
    />
  );

  return root;
};

export { HeaderComponents };

export default renderComponents;
