import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import CircularLoaders from '../../components/loaders';
import EmployeeItem from './item';

import styles from './styles/employees.module.scss';

const EMPTYLIST = 'Не найдено ни одного сотрудника';

class EmployeesComponents extends Component {
  static propTypes = {
    filterService: PropTypes.object.isRequired,
    employeesService: PropTypes.object.isRequired,
  };

  constructor({ filterService }) {
    super();

    const { searchFilter: { value, label } } = filterService.get();

    this.state = {
      loading: Boolean(value),
      searchValue: value,
      defaultMessage: label,
      list: [],
    };
  }

  UNSAFE_componentWillMount() {
    const { filterService, employeesService } = this.props;

    this.unsubscribeFilterServiceCb = filterService.subscribe(this.updateFilter);
    this.unsubscribeEmployeesServiceCb = employeesService.subscribe(this.updateEmployeesList);
  }

  componentWillUnmount() {
    this.props.filterService.cancelSearchFilter();

    if (this.unsubscribeFilterServiceCb) this.unsubscribeFilterServiceCb();
    if (this.unsubscribeEmployeesServiceCb) this.unsubscribeEmployeesServiceCb();
  }

  updateFilter = () => {
    const { filterService, employeesService } = this.props;
    const { searchFilter: { value } } = filterService.get();

    if (this.state.searchValue !== value) {
      employeesService.searchEmployees(value);

      this.setState({
        searchValue: value,
        loading: true,
      });
    }
  };

  updateEmployeesList = () => {
    this.setState({
      list: this.props.employeesService.get().employees,
      loading: false,
    });
  };

  renderLoading = () => <CircularLoaders wrapClassName='content-loader-wrap' />;

  renderDefaultMessage = () => (
    <div className='empty-list'>
      {this.state.defaultMessage}
    </div>
  );

  renderEmptyList = () => (
    <div className='empty-list'>
      {EMPTYLIST}
    </div>
  );

  render() {
    const { loading, list, searchValue } = this.state;
    const isSearch = !!searchValue;

    if (loading) return this.renderLoading();
    if (!loading && !list.length && !isSearch) return this.renderDefaultMessage();
    if (!loading && !list.length && isSearch) return this.renderEmptyList();

    return (
      <div className={ styles.wrap }>
        { list.map(item => (
          <EmployeeItem
            key={ item.Id }
            item={ item }
          />
        )) }
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <EmployeesComponents
      employeesService={ opts.employeesService }
      filterService={ opts.filterService }
      toEmployee={ opts.toEmployee }
    />
  );

  return root;
};

export default renderComponents;
