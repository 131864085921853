import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Autocomplete from 'react-autocomplete';
import Button from '../../components/button/Button';

import styles from './styles/hotelSearch.module.css';
import { FIELDS } from '../../bi/constants/hotels';

const INPUT_STYLE = {
  background: 'transparent',
  position: 'relative',
  zIndex: 5,
  border: 'none',
  width: '100%',
  padding: '2px',
  color: 'inherit',
  boxSizing: 'borderBox',
  font: 'inherit',
};

const WRAP_STYLE = { width: '100%' };

class HotelsSearch extends Component {
  static propTypes = {
    hotelsService: PropTypes.object.isRequired,
    searchHotelsInput: PropTypes.object.isRequired,
  };

  handleChangeHotelInput = async (value) => {
    const { hotelsService: { autocompleteHotel, changeField } } = this.props;
    changeField(value, FIELDS.SEARCH.VALUE);

    const suggest = await autocompleteHotel(value);
    changeField(suggest, FIELDS.SEARCH.SUGGEST);
  }

  handleSelectHotel = value => this.props.hotelsService.changeField(value, FIELDS.SEARCH.VALUE);

  handleSearchHotel = (e) => {
    e.preventDefault();
    const {
      searchHotelsInput: { value },
      hotelsService: { searchHotels },
    } = this.props;
    searchHotels(value);
  }

  renderMenu = (items) => {
    const { searchHotelsInput: { suggest } } = this.props;

    const regionLength = suggest.reduce((acc, { IsRegion }) => (IsRegion ? acc + 1 : acc), 0);
    const regionLabelStyle = { height: `${regionLength * 2.25}rem` };
    const regionLabel = regionLength ? <div className={ styles.label } style={ regionLabelStyle } >Регионы</div> : null;

    const isHotelsLength = suggest.some(({ IsRegion }) => !IsRegion);
    const hotelsLabel = isHotelsLength ? <div className={ styles.label } >Гостиницы</div> : null;

    return (
      <div className={ styles.autocomplete }>
        <div>
          {regionLabel}
          {hotelsLabel}
        </div>
        <div className={ styles.items_wrap }>
          {items}
        </div>
      </div>
    );
  }

  renderItem = item =>
    (<div key={ item.Id } className={ styles.autocomplete_item }>
      <div className={ styles.autocomplete_item_text }>{item.Name}</div>
      <div className={ styles.autocomplete_item_label }>{item.FullName}</div>
    </div>);

  render() {
    const { searchHotelsInput: { value: currentValue, suggest, label } } = this.props;

    const inputProps = {
      placeholder: label,
      style: INPUT_STYLE,
      type: 'search',
      required: true,
    };

    return (
      <form className={ styles.form_wrap } onSubmit={ this.handleSearchHotel }>
        <div className={ styles.input_wrap }>
          <Autocomplete
            value={ currentValue }
            items={ suggest }
            getItemValue={ item => item.Name }
            onSelect={ value => this.handleSelectHotel(value) }
            onChange={ ({ target: { value } }) => this.handleChangeHotelInput(value) }
            renderItem={ item => this.renderItem(item) }
            renderMenu={ elemens => this.renderMenu(elemens) }
            inputProps={ inputProps }
            wrapperStyle={ WRAP_STYLE }
          />
        </div>
        <Button
          label={ 'Найти Отель' }
          className={ styles.button_wrap }
          type='submit'
        />
      </form>
    );
  }
}

export default HotelsSearch;
