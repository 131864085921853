import renderComponents from './components';

const NAME = 'sw-employeesPage';
const DEFAULTLABELSEARCHINPUT = 'Поиск сотрудников';

class EmployeesModule {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    const filterService = context.getService('Filter');
    const employeesService = context.getService('Employees');

    filterService.setSearchFilter(DEFAULTLABELSEARCHINPUT, '');

    this.page = renderComponents(this.box, {
      filterService,
      employeesService,
    });
  }

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, EmployeesModule);
}
